import React from "react"
import logo from '../../assets/img/logo/logo1.webp'

class SpinnerComponent extends React.Component {
    render() {
        return (
            <div className="fallback-spinner d-flex align-items-center justify-content-center m-auto vh-100">
                 <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={logo} className="mr-auto ml-auto my-2" style={{maxWidth: "200px"}} alt="شرکت توسعه نرم افزار باران"/>
                     <h5>درحال لود شدن...</h5>
                </div>
{/*
                <div class="loader"></div>
*/}

            </div>
        )
    }
}

export default SpinnerComponent
